const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

const accessDetailsManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CLEAR_REDUCERS":
      return initialState;

    case "UPDATE_ORDER_ACCESS_DETAILS":
      return {
        ...state,
        isLoading: true,
      };

    case "UPDATE_ORDER_ACCESS_DETAILS_SUCCESS":
      return {
        ...state,
        isLoading: false,
        error: null,
      };

    case "UPDATE_ORDER_ACCESS_DETAILS_FAILURE":
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default accessDetailsManagementReducer;
