const initialState = {
  data: null,
  getLoading: false,
  updateLoading: false,
  error: null,
};

const providersManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CLEAR_REDUCERS":
      return initialState;

    case "GET_ORDER_PROVIDERS":
      return {
        ...initialState,
        getLoading: true,
      };

    case "GET_ORDER_PROVIDERS_SUCCESS":
      return {
        ...state,
        data: action.data,
        getLoading: false,
        error: null,
      };

    case "GET_ORDER_PROVIDERS_FAILURE":
      return {
        ...state,
        getLoading: false,
        error: action.error,
      };

    case "UPDATE_ORDER_PROVIDERS":
      return {
        ...state,
        updateLoading: true,
      };

    case "UPDATE_ORDER_PROVIDERS_SUCCESS":
      return {
        ...state,
        updateLoading: false,
        error: null,
      };

    case "UPDATE_ORDER_PROVIDERS_FAILURE":
      return {
        ...state,
        updateLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default providersManagementReducer;
