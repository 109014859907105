const initialState = {
  slots: null,
  nbTimeMax: 0,
  startDate: null,
  endDate: null,
  minStartDate: null,
  maxStartDate: null,
  calendar: null,
  isLoading: false,
  isSendLoading: false,
  isWeekLoading: false,
  error: null,
};

const rescheduleManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CLEAR_REDUCERS":
      return initialState;

    case "INIT_ORDER_PRODUCT_PLANNING":
      return {
        ...initialState,
        ...action.payload,
      };

    case "GET_ORDER_PRODUCT_SLOTS":
      return {
        ...state,
        slots: null,
        isLoading: true,
      };
    case "GET_ORDER_PRODUCT_SLOTS_SUCCESS":
      return {
        ...state,
        slots: action.slots,
        isLoading: false,
        error: null,
      };
    case "GET_ORDER_PRODUCT_SLOTS_ERROR":
      return {
        ...state,
        slots: null,
        isLoading: false,
        error: action.error,
      };
    case "GET_PREVIOUS_ORDER_PRODUCT_SLOTS":
      return {
        ...state,
        slots: null,
        isWeekLoading: true,
      };
    case "GET_PREVIOUS_ORDER_PRODUCT_SLOTS_SUCCESS":
      return {
        ...state,
        slots: action.slots,
        isWeekLoading: false,
        error: null,
      };
    case "GET_PREVIOUS_ORDER_PRODUCT_SLOTS_ERROR":
      return {
        ...state,
        slots: null,
        isWeekLoading: false,
        error: action.error,
      };
    case "GET_NEXT_ORDER_PRODUCT_SLOTS":
      return {
        ...state,
        slots: null,
        isWeekLoading: true,
      };
    case "GET_NEXT_ORDER_PRODUCT_SLOTS_SUCCESS":
      return {
        ...state,
        slots: action.slots,
        isWeekLoading: false,
        error: null,
      };
    case "GET_NEXT_ORDER_PRODUCT_SLOTS_ERROR":
      return {
        ...state,
        slots: null,
        isWeekLoading: false,
        error: action.error,
      };
    case "CREATE_UPDATE_CALENDAR":
      return {
        ...state,
        isLoading: false,
        calendar: action.payload.calendar,
        startDate: action.payload.startDate
          ? action.payload.startDate
          : state.startDate,
        endDate: action.payload.endDate
          ? action.payload.endDate
          : state.endDate,
      };
    case "SET_UPDATE_NB_TIME_MAX":
      return {
        ...state,
        nbTimeMax: action.number,
      };

    case "UPDATE_ORDER_PRODUCT_START_DATE":
      return {
        ...state,
        isSendLoading: true,
      };
    case "UPDATE_ORDER_PRODUCT_START_DATE_SUCCESS":
      return {
        ...state,
        isSendLoading: false,
      };
    case "UPDATE_ORDER_PRODUCT_START_DATE_ERROR":
      return {
        ...state,
        isSendLoading: false,
        error: action.error,
      };
    case "CLEAR_ORDER_UPDATE_PLANNING":
      return initialState;

    default:
      return state;
  }
};

export default rescheduleManagementReducer;
