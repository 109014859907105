import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

// import PaymentMethodContainer from "../../containers/orderManagement/PaymentMethodContainer";
// import InvoiceAddressContainer from "../../containers/orderManagement/InvoiceAddressContainer";
import ProviderContainer from "../../containers/orderManagement/ProviderContainer";
import AccessDetailsContainer from "../../containers/orderManagement/AccessDetailsContainer";
import RescheculeContainer from "../../containers/orderManagement/RescheduleContainer";

const classes = {
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "10px",
    marginBottom: 10,
  },
};

function OrderManagment(props) {
  const { classes, ...rest } = props;

  return (
    <div className={classes.root}>
      <RescheculeContainer {...rest} />
      <ProviderContainer {...rest} />
      {/* <PaymentMethodContainer />
      <InvoiceAddressContainer /> */}
      <AccessDetailsContainer {...rest} />
    </div>
  );
}

OrderManagment.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
  }).isRequired,
  onActionSuccess: PropTypes.func.isRequired,
  order: PropTypes.shape().isRequired,
};

export default withStyles(classes)(OrderManagment);
