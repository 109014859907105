import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { ErrorOutline } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import { orange } from "@material-ui/core/colors";

import { dateInTz } from "../../../../helpers/functions/dates";
import translation from "../../../../translation/translation";
import { getUserTimezone } from "../../../../helpers/functions/user";

const classes = {
  root: {
    display: "block",
    marginTop: 4,
    color: orange[600],
  },
  icon: {
    fontSize: "1rem",
    marginRight: 2,
    verticalAlign: "text-bottom",
  },
};

function LocalDateInfo(props) {
  const { classes, className, date, format, timezone } = props;

  if (timezone !== getUserTimezone()) {
    return (
      <span className={classNames(classes.root, className)}>
        <ErrorOutline className={classes.icon} />
        {translation().order_management.reschedule.hour_on_place}{" "}
        {dateInTz(date, format, timezone)} ({timezone})
      </span>
    );
  } else {
    return false;
  }
}

LocalDateInfo.defaultProps = {
  format: "localized-datetime",
};

LocalDateInfo.propTypes = {
  className: PropTypes.string,
  date: PropTypes.any.isRequired,
  format: PropTypes.oneOf([
    "iso",
    "localized-date",
    "localized-long-date",
    "localized-long-datetime",
    "localized-datetime",
    "localized-long-datetime-with-seconds",
    "localized-datetime-with-seconds",
    PropTypes.string,
  ]),
  timezone: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(classes)(LocalDateInfo);
