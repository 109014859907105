export const getOrderProductSlots = () => ({
  type: "GET_ORDER_PRODUCT_SLOTS",
});

export const getOrderProductSlotsSuccess = (slots) => ({
  type: "GET_ORDER_PRODUCT_SLOTS_SUCCESS",
  slots,
});

export const getOrderProductSlotsError = (error) => ({
  type: "GET_ORDER_PRODUCT_SLOTS_ERROR",
  error,
});

export const getPreviousOrderProductSlots = () => ({
  type: "GET_PREVIOUS_ORDER_PRODUCT_SLOTS",
});

export const getPreviousOrderProductSlotsSuccess = (slots) => ({
  type: "GET_PREVIOUS_ORDER_PRODUCT_SLOTS_SUCCESS",
  slots,
});

export const getPreviousOrderProductSlotsError = (error) => ({
  type: "GET_PREVIOUS_ORDER_PRODUCT_SLOTS_ERROR",
  error,
});

export const getNextOrderProductSlots = () => ({
  type: "GET_NEXT_ORDER_PRODUCT_SLOTS",
});

export const getNextOrderProductSlotsSuccess = (slots) => ({
  type: "GET_NEXT_ORDER_PRODUCT_SLOTS_SUCCESS",
  slots,
});

export const getNextOrderProductSlotsError = (error) => ({
  type: "GET_NEXT_ORDER_PRODUCT_SLOTS_ERROR",
  error,
});

export const createUpdateCalendar = (calendar, startDate, endDate) => ({
  type: "CREATE_UPDATE_CALENDAR",
  payload: {
    calendar,
    startDate,
    endDate,
  },
});

export const setUpdateNbTimeMax = (number) => ({
  type: "SET_UPDATE_NB_TIME_MAX",
  number,
});

export const clearOrderUpdatePlanning = () => ({
  type: "CLEAR_ORDER_UPDATE_PLANNING",
});

export const updateOrderProductStartDate = () => ({
  type: "UPDATE_ORDER_PRODUCT_START_DATE",
});

export const updateOrderProductStartDateSuccess = () => ({
  type: "UPDATE_ORDER_PRODUCT_START_DATE_SUCCESS",
});

export const updateOrderProductStartDateError = (error) => ({
  type: "UPDATE_ORDER_PRODUCT_START_DATE_ERROR",
  error,
});

export const initOrderProductPlanning = (payload) => ({
  type: "INIT_ORDER_PRODUCT_PLANNING",
  payload,
});
