import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { Visibility, Launch, Work } from "@material-ui/icons";

import { addThreeDots } from "../../../helpers/functions/utils";
import { dateInTz } from "../../../helpers/functions/dates";
import translation from "../../../translation/translation";

const styles = () => ({
  paper: {
    overflowX: "auto",
  },
  head: {
    backgroundColor: grey[200],
  },
  title: {
    color: "black",
    fontWeight: 600,
  },
  status: {
    display: "flex",
    alignItems: "center",
  },
  icon_status: {
    width: 20,
    height: 20,
    marginRight: 5,
  },
  icon_address: {
    width: 15,
    height: 15,
    marginLeft: 5,
    verticalAlign: "middle",
    color: grey[500],
  },
  icon_time: {
    width: 15,
    height: 15,
    marginRight: 5,
    verticalAlign: "middle",
    color: grey[600],
  },
});

function OrdersTable(props) {
  const { classes, orders, onSelectOrderProduct, user } = props;

  return (
    <div className="orders-table">
      <Paper className={classes.paper}>
        <Table>
          <TableHead className={classes.head}>
            <TableRow>
              {user.data && user.data.company && user.data.company_admin && (
                <TableCell align="left" className={classes.title}>
                  {translation().orders.table.provider}
                </TableCell>
              )}
              <TableCell align="left" className={classes.title}>
                {translation().orders.table.address}
              </TableCell>
              <TableCell align="left" className={classes.title}>
                {translation().orders.table.date}
              </TableCell>
              <TableCell align="left" className={classes.title}>
                {translation().orders.table.time}
              </TableCell>
              <TableCell align="left" className={classes.title}>
                {translation().orders.table.status}
              </TableCell>
              <TableCell align="left" className={classes.title}>
                {translation().orders.table.product}
              </TableCell>
              <TableCell align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((data) => (
              <TableRow key={data.id}>
                {user.data && user.data.company && user.data.company_admin && (
                  <TableCell align="left">
                    <div className="provider">
                      <Work className="provider-icon" /> {data.provider_name}
                    </div>
                  </TableCell>
                )}
                <TableCell align="left">
                  {data.order &&
                  data.order.property &&
                  data.order.property.address
                    ? addThreeDots(data.order.property.address, 82)
                    : ""}
                  <a
                    target="_blank"
                    href={
                      "http://maps.google.com?q=" + data.order.property.address
                    }
                    rel="noopener noreferrer"
                  >
                    <Launch className={classes.icon_address} />
                  </a>
                </TableCell>
                <TableCell align="left">
                  {data.start ? (
                    <span>
                      <span className="planning-icon icon-calendrier" />{" "}
                      {dateInTz(data.start, "localized-date")}
                    </span>
                  ) : (
                    <br />
                  )}
                </TableCell>
                <TableCell align="left">
                  {data.start ? (
                    <span>
                      <span className="planning-icon icon-horaire" />{" "}
                      {dateInTz(data.start, "HH:mm")}
                    </span>
                  ) : (
                    <br />
                  )}
                </TableCell>
                <TableCell align="left">
                  <div
                    className={classes.status}
                    style={{
                      color: data.delivery_status.color
                        ? data.delivery_status.color
                        : "#000000",
                    }}
                  >
                    <img
                      alt="Status"
                      src={
                        data.delivery_status &&
                        data.delivery_status.icon_url_dark
                          ? data.delivery_status.icon_url_dark
                          : ""
                      }
                      className={classes.icon_status}
                    />
                    {data.delivery_status && data.delivery_status.name
                      ? data.delivery_status.name
                      : ""}
                  </div>
                </TableCell>
                <TableCell align="left">
                  <div className="products-img">
                    <div
                      className={
                        "product-img " + (data.uploadable ? "" : "unavailable")
                      }
                    >
                      <img
                        src={data.icon_url_dark ? data.icon_url_dark : ""}
                        alt={data.name ? data.name : "Missing img"}
                      />
                    </div>
                    <span
                      className={
                        "name " + (data.uploadable ? "" : "unavailable")
                      }
                    >
                      {data.name ? addThreeDots(data.name, 35) : ""}
                    </span>
                  </div>
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    aria-label="View order product"
                    onClick={() => onSelectOrderProduct(data.id)}
                  >
                    <Visibility fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
}

OrdersTable.propTypes = {
  classes: PropTypes.shape({
    paper: PropTypes.string.isRequired,
    head: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    icon_status: PropTypes.string.isRequired,
    icon_address: PropTypes.string.isRequired,
  }).isRequired,
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      order: PropTypes.shape({
        property: PropTypes.shape({
          address: PropTypes.string,
        }),
        product: PropTypes.shape({
          name: PropTypes.string,
        }),
      }),
      start: PropTypes.string,
      delivery_status: PropTypes.shape({
        color: PropTypes.string,
        icon_url_dark: PropTypes.string,
        name: PropTypes.string,
      }),
      icon_url_dark: PropTypes.string,
      name: PropTypes.string,
      uploadable: PropTypes.bool,
    })
  ).isRequired,
  user: PropTypes.shape({
    data: PropTypes.shape({
      company: PropTypes.shape({
        id: PropTypes.number,
      }),
      company_admin: PropTypes.bool,
    }),
  }).isRequired,
  onSelectOrderProduct: PropTypes.func.isRequired,
};

export default withStyles(styles)(OrdersTable);
