import { fetchApi } from "./api";

import {
  storeMediaType,
  removeMediaTypes,
  storeMediaTypeMedia,
  removeMediaTypeMedia,
  removeMediaTypeMedias,
  storeOpmInitBuffer,
  storeOpmProductId,
  storeMediaTypeProperty,
  removeOpmInitBuffer,
} from "../../redux/actions/providerActions";

import config from "../../config";
import store from "../../redux/store";

/**
 * Create an availability
 */
export const apiPostAvailability = (
  providerId,
  availability,
  callBackSuccess,
  callBackError
) => {
  if (!availability) return;

  if (callBackSuccess && callBackError) {
    fetchApi(
      "post",
      providerId
        ? "provider-companies/self/providers/" + providerId + "/availabilities"
        : "providers/self/availabilities",
      null,
      availability,
      null,
      callBackSuccess,
      callBackError
    );
  }
};

/**
 * Create an unavailability
 */
export const apiPostUnavailability = (
  providerId,
  unavailability,
  callBackSuccess,
  callBackError
) => {
  if (!unavailability) return;

  if (callBackSuccess && callBackError)
    fetchApi(
      "post",
      providerId
        ? "provider-companies/self/providers/" +
            providerId +
            "/unavailabilities"
        : "providers/self/unavailabilities",
      null,
      unavailability,
      null,
      callBackSuccess,
      callBackError
    );
};

/**
 * Update an availability
 */
export const apiPutAvailability = (
  providerId,
  id,
  availability,
  callBackSuccess,
  callBackError
) => {
  if (!availability || !id) return;

  if (callBackSuccess && callBackError)
    fetchApi(
      "put",
      providerId
        ? "provider-companies/self/providers/" +
            providerId +
            "/availabilities/" +
            id
        : "providers/self/availabilities/" + id,
      null,
      availability,
      null,
      callBackSuccess,
      callBackError
    );
};

/**
 * Update an unavailability
 */
export const apiPutUnavailability = (
  providerId,
  id,
  unavailability,
  callBackSuccess,
  callBackError
) => {
  if (!unavailability || !id) return;

  if (callBackSuccess && callBackError)
    fetchApi(
      "put",

      providerId
        ? "provider-companies/self/providers/" +
            providerId +
            "/unavailabilities/" +
            id
        : "providers/self/unavailabilities/" + id,
      null,
      unavailability,
      null,
      callBackSuccess,
      callBackError
    );
};

/**
 * Get all availabilities
 */
export const apiGetAvailabilities = (
  next = null,
  query = {},
  callBackSuccess,
  callBackError
) => {
  if (callBackSuccess && callBackError) {
    fetchApi(
      "get",
      next ? next.replace(config.api_url, "") : "providers/self/availabilities",
      query,
      null,
      null,
      callBackSuccess,
      callBackError
    );
  }
};

/**
 * Delete an availability
 */
export const apiDeleteAvailabilities = (
  providerId,
  id,
  callBackSuccess,
  callBackError
) => {
  if (!id) return;

  if (callBackSuccess && callBackError)
    fetchApi(
      "delete",
      providerId
        ? "provider-companies/self/providers/" +
            providerId +
            "/availabilities/" +
            id
        : "providers/self/availabilities/" + id,
      null,
      null,
      null,
      callBackSuccess,
      callBackError
    );
};

/**
 * Delete an unavailability
 */
export const apiDeleteUnavailabilities = (
  providerId,
  id,
  callBackSuccess,
  callBackError
) => {
  if (!id) return;

  if (callBackSuccess && callBackError)
    fetchApi(
      "delete",
      providerId
        ? "provider-companies/self/providers/" +
            providerId +
            "/unavailabilities/" +
            id
        : "providers/self/unavailabilities/" + id,
      null,
      null,
      null,
      callBackSuccess,
      callBackError
    );
};

/**
 * Get all orders for a provider
 */
export const apiGetProviderOrders = (
  status,
  limit,
  routePage,
  query = {},
  callBackSuccess,
  callBackError
) => {
  if (callBackSuccess && callBackError) {
    if (routePage) {
      routePage = routePage.replace(config.api_url, ""); //<-- Replace the api route name delivred by api
      fetchApi(
        "get",
        routePage,
        limit ? { limit: limit } : null,
        null,
        null,
        callBackSuccess,
        callBackError
      );
    } else {
      fetchApi(
        "get",
        "providers/self/order-products" + (status ? "/" + status : ""),
        {
          limit: limit ? limit : null,
          sort: status === "planned" || status === "done" ? "start" : "-start",
          ...query,
        },
        null,
        null,
        callBackSuccess,
        callBackError
      );
    }
  }
};

export const apiGetProviderOrderProducts = (
  query = {},
  routePage,
  callBackSuccess,
  callBackError
) => {
  if (callBackSuccess && callBackError) {
    if (routePage) {
      routePage = routePage.replace(config.api_url, "");
      fetchApi(
        "get",
        routePage,
        null,
        null,
        null,
        callBackSuccess,
        callBackError
      );
    } else {
      fetchApi(
        "get",
        "providers/self/order-products",
        query,
        null,
        null,
        callBackSuccess,
        callBackError
      );
    }
  }
};

/**
 * Post all medias for an orderProduct
 */
export const apiPostMediasOrder = (
  orderProductId,
  mediasOrder,
  callBackSuccess,
  callBackError
) => {
  if (!mediasOrder || !orderProductId) return;

  if (callBackSuccess && callBackError) {
    fetchApi(
      "post",
      "providers/self/order-products/" + orderProductId + "/medias",
      null,
      mediasOrder,
      null,
      callBackSuccess,
      callBackError
    );
  }
};

/**
 * Set a mediaType in redux
 */
export const setMediaType = (mediaType) => {
  if (mediaType) {
    store.dispatch(storeMediaType(mediaType));
  }
};

/**
 * Remove into redux all mediaTypes
 */
export const unsetMediaTypes = () => {
  store.dispatch(removeMediaTypes());
};

/**
 * Add a media in a mediaType in redux
 */
export const setMediaTypeMedia = (media, mediaTypeShortcode) => {
  if (media && mediaTypeShortcode) {
    store.dispatch(storeMediaTypeMedia(media, mediaTypeShortcode));
  }
};

/**
 * Get all medias for a mediaType from redux for a given shortcode
 */
export const getMediaTypeMedias = (mediaTypeShortcode) => {
  const state = store.getState();
  const medias = state.orderProductMedias[mediaTypeShortcode].medias;
  return medias;
};

/**
 * Get all mediaTypes from redux
 */
export const getOrderProductMedias = () => {
  const state = store.getState();
  const medias = state.orderProductMedias;
  return medias;
};

/**
 * Remove a media from a mediaType in redux
 */
export const unsetMediaTypeMedia = (mediaUrl, mediaTypeShortcode) => {
  if (mediaUrl && mediaTypeShortcode) {
    store.dispatch(removeMediaTypeMedia(mediaUrl, mediaTypeShortcode));
  }
};

/**
 * Set a property media type to a mediaType in redux
 */
export const setMediaTypeProperty = (
  mediaUrl,
  mediaTypeShortcode,
  mediaProperty,
  mediaPropertyValue
) => {
  if (mediaUrl && mediaTypeShortcode && mediaProperty && mediaPropertyValue) {
    store.dispatch(
      storeMediaTypeProperty(
        mediaUrl,
        mediaTypeShortcode,
        mediaProperty,
        mediaPropertyValue
      )
    );
  }
};

/**
 * Reset all medias in a mediaType in redux
 */
export const resetMediaTypeMedias = (mediaTypeShortcode) => {
  if (mediaTypeShortcode) {
    store.dispatch(removeMediaTypeMedias(mediaTypeShortcode));
  }
};

/**
 * Set the orderProductMedias status
 */
export const setOpmInitBuffer = (status) => {
  if (status) {
    store.dispatch(storeOpmInitBuffer(status));
  }
};

/**
 * Set the orderProductMedias product id
 */
export const setOpmProductId = (productId) => {
  if (productId) {
    store.dispatch(storeOpmProductId(productId));
  }
};

/**
 * Reset the orderProductMedias status bucket
 */
export const resetOpmInitBuffer = () => {
  store.dispatch(removeOpmInitBuffer());
};

/**
 * Get the orderProductMedias product id
 */
export const getOpmProductId = () => {
  const state = store.getState();
  const orderProductMediasStatus = state.orderProductMediasStatus;
  return orderProductMediasStatus;
};

/**
 * Get orderProduct media types
 */
export const apiGetProductMediaGroups = (
  orderProductId,
  callBackSuccess,
  callBackError
) => {
  if (!orderProductId) {
    return;
  }

  if (callBackSuccess && callBackError) {
    fetchApi(
      "get",
      "providers/self/order-products/" + orderProductId + "/medias",
      null,
      null,
      null,
      callBackSuccess,
      callBackError
    );
  }
};

/**
 * Get all templates for a media type
 */
export const apiGetMediaTypeTemplates = (
  value,
  mediaTypeId,
  callBackSuccess,
  callBackError
) => {
  if (!mediaTypeId || !value) {
    return;
  }

  if (callBackSuccess && callBackError) {
    fetchApi(
      "get",
      "media-types/" + mediaTypeId + "/templates",
      { filters: "name|lk|" + value, sort: "name", limit: 5 },
      null,
      null,
      callBackSuccess,
      callBackError
    );
  }
};

/**
 * Get providers products
 */
export const getProviderProducts = (
  paging,
  query = {},
  callBackSuccess,
  callBackError
) => {
  fetchApi(
    "get",
    paging ? paging.replace(config.api_url, "") : "providers/self/products",
    query,
    null,
    null,
    callBackSuccess,
    callBackError
  );
};

/**
 * Get providers events  : availabilities, orders products, product carts...
 */
export const apiGetProviderCalendar = (
  providerId = null,
  query = {},
  callBackSuccess,
  callBackError
) => {
  if (callBackSuccess && callBackError) {
    fetchApi(
      "get",
      providerId
        ? "provider-companies/self/providers/" + providerId + "/cal"
        : "providers/self/cal",
      query,
      null,
      null,
      callBackSuccess,
      callBackError
    );
  }
};

export const apiGetProviderHash = (
  query = {},
  callBackSuccess,
  callBackError
) => {
  if (callBackSuccess && callBackError) {
    fetchApi(
      "get",
      "providers/self/hash",
      query,
      null,
      null,
      callBackSuccess,
      callBackError
    );
  }
};
