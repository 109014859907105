import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { blue, grey } from "@material-ui/core/colors";
import { Edit } from "@material-ui/icons";

import translation from "../../../translation/translation";
import {
  addToDate,
  createDate,
  dateInTz,
  formatDate,
  getCurrentDate,
  toUtcTimestamp,
} from "../../../helpers/functions/dates";
import Planning from "./planning/Planning";
import LocalDateInfo from "./planning/LocalDateInfo";
import PlanningUnavailableIcon from "./planning/PlanningUnavailableIcon";

const classes = {
  button: {
    borderRadius: 4,
    fontFamily: "'Montserrat-Regular'",
    lineHeight: "1.2",
    color: blue[500],
    borderColor: blue[500],
    "&:hover": {
      color: blue[500],
      borderColor: blue[500],
      backgroundColor: "rgb(100 181 246 / 15%)",
    },
  },
  editIcon: {
    marginRight: 5,
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    marginTop: 15,
    gap: "10px",
    color: grey[500],
  },
  currentDateText: {
    display: "flex",
    fontWeight: 500,
  },
  currentDateTitle: {
    display: "flex",
    marginRight: 4,
  },
  currentDateWpr: {
    display: "flex",
    alignItems: "center",
  },
  planningError: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

class RescheduleMethodDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpened: false,
      value: "",
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChangeSlot = this.handleChangeSlot.bind(this);
    this.handleEnterDialog = this.handleEnterDialog.bind(this);
    this.handleClickNextWeek = this.handleClickNextWeek.bind(this);
    this.handleClickPreviousWeek = this.handleClickPreviousWeek.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { initOrderProductPlanning } = this.props;

    initOrderProductPlanning({
      startDate: formatDate(getCurrentDate(), "YYYY-MM-DD"),
      endDate: formatDate(addToDate(getCurrentDate(), 7, "days"), "YYYY-MM-DD"),
      minStartDate: formatDate(getCurrentDate(), "YYYY-MM-DD"),
      maxStartDate: formatDate(
        addToDate(getCurrentDate(), 3, "months"),
        "YYYY-MM-DD"
      ),
    });
  }

  componentWillUnmount() {
    const { clearOrderUpdatePlanning } = this.props;

    clearOrderUpdatePlanning();
  }

  handleOpen() {
    this.setState({ isOpened: true });
  }

  handleClose() {
    const { initOrderProductPlanning } = this.props;

    initOrderProductPlanning({
      startDate: formatDate(getCurrentDate(), "YYYY-MM-DD"),
      endDate: formatDate(addToDate(getCurrentDate(), 7, "days"), "YYYY-MM-DD"),
      minStartDate: formatDate(getCurrentDate(), "YYYY-MM-DD"),
      maxStartDate: formatDate(
        addToDate(getCurrentDate(), 3, "months"),
        "YYYY-MM-DD"
      ),
    });

    this.setState({ isOpened: false, value: "" });
  }

  getOptionsIds(options) {
    if (Array.isArray(options)) {
      const optionsIds = [];

      if (!options || options.length <= 0) return [];

      for (let i = 0; i < options.length; i++) {
        if (options[i].product_option_id)
          optionsIds.push(options[i].product_option_id);
      }

      return optionsIds;
    } else {
      return false;
    }
  }

  handleEnterDialog() {
    const {
      getOrderProductSlots,
      order: { id, order, options },
      startDate,
      endDate,
    } = this.props;

    const timezone =
      order && order.property && order.property.timezone_code
        ? order.property.timezone_code
        : "";

    getOrderProductSlots(
      id,
      this.getOptionsIds(options),
      startDate,
      endDate,
      timezone
    );
  }

  handleChangeSlot(date) {
    const {
      order: { order },
    } = this.props;

    const timezone =
      order && order.property && order.property.timezone_code
        ? order.property.timezone_code
        : "";

    const slotSelected = createDate(date, timezone);
    const slotSelectedUTC = toUtcTimestamp(slotSelected);

    this.setState({ value: slotSelectedUTC });
  }

  handleClickNextWeek() {
    const {
      getNextOrderProductSlots,
      order: { id, order, options },
      startDate,
      endDate,
    } = this.props;

    const timezone =
      order && order.property && order.property.timezone_code
        ? order.property.timezone_code
        : "";

    getNextOrderProductSlots(
      id,
      this.getOptionsIds(options),
      startDate,
      endDate,
      timezone
    );
  }

  handleClickPreviousWeek() {
    const {
      getPreviousOrderProductSlots,
      order: { id, order, options },
      startDate,
      endDate,
    } = this.props;

    const timezone =
      order && order.property && order.property.timezone_code
        ? order.property.timezone_code
        : "";

    getPreviousOrderProductSlots(
      id,
      this.getOptionsIds(options),
      startDate,
      endDate,
      timezone
    );
  }

  handleSubmit() {
    const { value } = this.state;

    if (value) {
      const {
        order: { id },
        updateOrderProductPlanning,
      } = this.props;

      updateOrderProductPlanning(id, value, this.handleClose);
    }
  }

  render() {
    const { isOpened, value } = this.state;
    const {
      order: { id, start, order },
      classes,
      slots,
      nbTimeMax,
      startDate,
      endDate,
      minStartDate,
      maxStartDate,
      calendar,
      isLoading,
      isSendLoading,
      isWeekLoading,
      error,
    } = this.props;

    return (
      <React.Fragment>
        <Button
          variant="outlined"
          color="primary"
          onClick={this.handleOpen}
          className={classes.button}
          size="small"
        >
          <Edit fontSize="small" className={classes.editIcon} />
          {translation().order_management.reschedule.button}
        </Button>
        <Dialog
          fullWidth
          maxWidth="md"
          open={isOpened}
          onClose={this.handleClose}
          aria-labelledby="update-date-start-order-product"
          aria-describedby="update-date-start-order-product-description"
          disableBackdropClick={isLoading || isWeekLoading || isSendLoading}
          onEnter={this.handleEnterDialog}
        >
          <DialogTitle id="update-date-start-order-product">
            {translation().order_management.reschedule.title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="update-date-start-order-product-description"
              component="div"
            >
              <div className={classes.currentDateWpr}>
                <Typography
                  className={classes.currentDateTitle}
                  variant="body1"
                >
                  {translation().order_management.reschedule.current}:
                </Typography>
                <Typography className={classes.currentDateText} variant="body1">
                  {dateInTz(start, "localized-long-datetime")}
                </Typography>
              </div>
              <LocalDateInfo
                timezone={
                  order && order.property && order.property.timezone_code
                    ? order.property.timezone_code
                    : ""
                }
                date={start}
                className={classes.currentDateWpr}
              />
              <Typography variant="body1">
                {translation().order_management.reschedule.description}
              </Typography>
            </DialogContentText>
            {!isLoading && calendar && (
              <Planning
                slots={slots}
                nbTimeMax={nbTimeMax}
                startDate={startDate}
                endDate={endDate}
                minStartDate={minStartDate}
                maxStartDate={maxStartDate}
                isWeekLoading={isWeekLoading}
                calendar={calendar}
                orderProductId={id}
                timezone={
                  order && order.property && order.property.timezone_code
                    ? order.property.timezone_code
                    : ""
                }
                onSlotChange={this.handleChangeSlot}
                onClickNextWeek={this.handleClickNextWeek}
                onClickPreviousWeek={this.handleClickPreviousWeek}
                disabled={isSendLoading}
              />
            )}
            {error && !calendar && (
              <div className={classes.planningError}>
                <PlanningUnavailableIcon height={70} width={70} />
              </div>
            )}
            {isLoading && (
              <div className={classes.loading}>
                <CircularProgress size={30} color="secondary" />
                <p>{translation().order_management.reschedule.loading_text}</p>
              </div>
            )}
            {isSendLoading && (
              <div className={classes.loading}>
                <CircularProgress size={30} color="secondary" />
                <p>{translation().order_management.reschedule.sending_text}</p>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              color="default"
              disabled={isLoading || isWeekLoading || isSendLoading}
              size="small"
            >
              {translation().commons.cancel}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleSubmit}
              disabled={isLoading || isWeekLoading || isSendLoading || !value}
              size="small"
            >
              {translation().commons.confirm}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

RescheduleMethodDialog.propTypes = {
  classes: PropTypes.shape({
    loading: PropTypes.string.isRequired,
    editIcon: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
    currentDateText: PropTypes.string.isRequired,
    currentDateTitle: PropTypes.string.isRequired,
    currentDateWpr: PropTypes.string.isRequired,
    planningError: PropTypes.string.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool,
  isSendLoading: PropTypes.bool,
  order: PropTypes.shape({
    start: PropTypes.any.isRequired,
    id: PropTypes.number.isRequired,
    order: PropTypes.shape({
      property: PropTypes.shape({
        timezone_code: PropTypes.string.isRequired,
      }),
    }),
    options: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  error: PropTypes.string,
  isWeekLoading: PropTypes.bool,
  calendar: PropTypes.shape(),
  clearOrderUpdatePlanning: PropTypes.func.isRequired,
  initOrderProductPlanning: PropTypes.func.isRequired,
  getOrderProductSlots: PropTypes.func.isRequired,
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  minStartDate: PropTypes.any,
  maxStartDate: PropTypes.any,
  slots: PropTypes.arrayOf(PropTypes.string),
  nbTimeMax: PropTypes.number,
  getNextOrderProductSlots: PropTypes.func.isRequired,
  getPreviousOrderProductSlots: PropTypes.func.isRequired,
  updateOrderProductPlanning: PropTypes.func.isRequired,
};

export default withStyles(classes)(RescheduleMethodDialog);
