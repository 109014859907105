import { connect } from "react-redux";

import AccessDetailsDialog from "../../components/orderManagement/AccessDetailsDialog";
import {
  updateAccessDetails,
  updateAccessDetailsFailure,
  updateAccessDetailsSuccess,
} from "../../redux/actions/orderManagement/accessDetails";
import { enqueueSnackbar } from "../../redux/actions/notificationsActions";
import translation from "../../translation/translation";
import { fetchApi } from "../../helpers/functions/api";

const mapStateToProps = (state) => ({
  user: state.user.data,
  isLoading: state.orderManagement.accessDetails.isLoading,
  data: state.orderManagement.accessDetails.data,
  error: state.orderManagement.accessDetails.error,
});

const mapDispatchToProps = (dispatch, { order, onActionSuccess }) => {
  return {
    updateAccessDetails(data, onSuccess) {
      if (!order || !order.id) {
        return;
      }

      dispatch(updateAccessDetails());

      fetchApi(
        "put",
        "provider-companies/self/order-products/" + order.id + "/details",
        null,
        data,
        null,
        (data) => {
          dispatch(updateAccessDetailsSuccess(data));

          dispatch(
            enqueueSnackbar({
              message: translation().order_management.access_details.update
                .success,
              options: {
                variant: "success",
              },
            })
          );

          if (onActionSuccess) {
            onActionSuccess();
          }

          if (onSuccess) {
            onSuccess();
          }
        },
        (error) => {
          dispatch(updateAccessDetailsFailure(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().order_management.access_details.update
                        .error,
                status: error.response.status || undefined,
                options: {
                  variant: "error",
                },
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().order_management.access_details.update
                  .error,
                options: {
                  variant: "error",
                },
              })
            );
          }
        }
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccessDetailsDialog);
