import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { grey, orange, red } from "@material-ui/core/colors";
import { ChevronRight, ChevronLeft, ErrorOutline } from "@material-ui/icons";
import {
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from "@material-ui/core";

import {
  createDate,
  dateInTz,
  formatDate,
  toUtcTimestamp,
} from "../../../../helpers/functions/dates";
import translation from "../../../../translation/translation";
import { getUserTimezone } from "../../../../helpers/functions/user";
import { objectMapToArray } from "../../../../helpers/functions/utils";

const classes = {
  tableContainer: {
    maxHeight: 455,
    overflow: "auto",
  },
  tableHead: {
    backgroundColor: grey[100],
  },
  tableCell: {
    borderColor: "transparent",
  },
  title: {
    color: grey[700],
    marginBottom: 12,
  },
  timeSlot: {
    "&:hover": {
      backgroundColor: orange[300],
      borderColor: orange[300],
      color: "#FFFFFF",
    },
    "&.selected": {
      backgroundColor: orange[300],
      borderColor: orange[300],
      color: "#FFFFFF",
    },
  },
  nav: {
    height: 56,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderBottom: "1px solid #e0e0e0",
  },
  timezoneWarning: {
    backgroundColor: orange[600],
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 8,
    color: "#FFFFFF",
    gap: "5px",
  },
  timezoneWarningText: {
    color: "#FFFFFF",
  },
  timezoneErrorText: {
    color: red[400],
    textAlign: "center",
    marginTop: 15,
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    marginTop: 15,
    gap: "10px",
    color: grey[500],
  },
};

class Planning extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: null,
    };

    this.handleSlotChange = this.handleSlotChange.bind(this);
  }

  handleSlotChange(time, date) {
    const { timezone, onSlotChange } = this.props;

    if (!time || !date) return;

    if (time !== "Invalid date" || date !== "Invalid date") {
      const slotSelected = createDate(date + " " + time, timezone);
      const slotSelectedUTC = toUtcTimestamp(slotSelected);

      this.setState({ selected: slotSelectedUTC });

      if (onSlotChange) {
        onSlotChange(slotSelectedUTC);
      }
    }
  }

  render() {
    const {
      classes,
      disabled,
      onClickNextWeek,
      onClickPreviousWeek,
      startDate,
      minStartDate,
      maxStartDate,
      calendar,
      nbTimeMax,
      isWeekLoading,
      timezone,
    } = this.props;

    const { selected } = this.state;

    const startDateFormated = formatDate(startDate, "YYYY-MM-DD");
    const minStartDateFormated = formatDate(minStartDate, "YYYY-MM-DD");
    const maxStartDateFormated = formatDate(maxStartDate, "YYYY-MM-DD");

    const isPrevDisabled =
      startDateFormated <= minStartDateFormated ? true : false;
    const isNextDisabled =
      startDateFormated >= maxStartDateFormated ? true : false;

    if (timezone) {
      return (
        <div className="Planning">
          <Typography variant="body1" component="p" className={classes.title}>
            {translation().order_management.reschedule.slots.label}:
          </Typography>
          <div className="Planning-container">
            {timezone && timezone !== getUserTimezone() && (
              <div className={classes.timezoneWarning}>
                <ErrorOutline fontSize="small" />
                <Typography
                  color="inherit"
                  component="p"
                  className={classes.timezoneWarningText}
                >
                  {translation().order_management.reschedule.timezone_diff} (
                  {timezone})
                </Typography>
              </div>
            )}
            <div className={classes.tableContainer}>
              <Table>
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell align="center" className={classes.tableCell}>
                      <IconButton
                        aria-label="Load previous week"
                        size="small"
                        disabled={isPrevDisabled}
                        onClick={onClickPreviousWeek}
                      >
                        <ChevronLeft fontSize="small" />
                      </IconButton>
                    </TableCell>
                    {calendar &&
                      Object.keys(calendar).map((date, index) => {
                        return (
                          <TableCell
                            key={index}
                            align="center"
                            className={classes.tableCell}
                          >
                            <Typography variant="subtitle2" component="p">
                              {dateInTz(date, "dddd")}
                            </Typography>
                            <Typography variant="caption" component="span">
                              {dateInTz(date, "localized-date")}
                            </Typography>
                          </TableCell>
                        );
                      })}
                    <TableCell align="center" className={classes.tableCell}>
                      <IconButton
                        aria-label="Load next week"
                        size="small"
                        disabled={isNextDisabled}
                        onClick={onClickNextWeek}
                      >
                        <ChevronRight fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell />
                    {calendar &&
                      objectMapToArray(calendar, (date, times, index) => {
                        let nbEmptyTimes = Math.max(
                          0,
                          nbTimeMax - times.length
                        );
                        let emptySlots = [];

                        for (let g = 0; g < nbEmptyTimes; g++) {
                          emptySlots.push(g);
                        }
                        return (
                          <TableCell key={index} align="center">
                            {times.map((time, index) => {
                              return (
                                <div
                                  key={index}
                                  className={classNames(
                                    classes.timeSlot,
                                    "time-slot",
                                    {
                                      selected:
                                        selected ===
                                        toUtcTimestamp(
                                          createDate(
                                            date + " " + time,
                                            timezone
                                          )
                                        ),
                                    }
                                  )}
                                  onClick={() =>
                                    this.handleSlotChange(time, date)
                                  }
                                >
                                  <Typography variant="body2" component="span">
                                    {time}
                                  </Typography>
                                </div>
                              );
                            })}
                            {emptySlots.map((elem, index) => {
                              return (
                                <div key={index} className="empty-slot">
                                  <span />
                                </div>
                              );
                            })}
                          </TableCell>
                        );
                      })}
                    <TableCell />
                  </TableRow>
                </TableBody>
              </Table>
            </div>
            {isWeekLoading && (
              <div className="Planning-overlay">
                <div className={classes.loading}>
                  <CircularProgress size={28} color="secondary" />
                  <p>
                    {
                      translation().order_management.reschedule.slots
                        .loading_text
                    }
                  </p>
                </div>
              </div>
            )}
            {disabled && (
              <div className="Planning-overlay">
                <div className={classes.loading} />
              </div>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <p className={classes.timezoneErrorText}>
          {`${
            translation().order_management.reschedule.timezone_not_found
          } (error code: property_timezone_not_found)`}
        </p>
      );
    }
  }
}

Planning.propTypes = {
  disabled: PropTypes.bool,
  isWeekLoading: PropTypes.bool,
  startDate: PropTypes.any,
  minStartDate: PropTypes.any,
  maxStartDate: PropTypes.any,
  calendar: PropTypes.shape().isRequired,
  nbTimeMax: PropTypes.number,
  onSlotChange: PropTypes.func.isRequired,
  onClickNextWeek: PropTypes.func.isRequired,
  onClickPreviousWeek: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    tableContainer: PropTypes.string.isRequired,
    tableCell: PropTypes.string.isRequired,
    tableHead: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    timeSlot: PropTypes.string.isRequired,
    nav: PropTypes.string.isRequired,
    timezoneWarning: PropTypes.string.isRequired,
    timezoneWarningText: PropTypes.string.isRequired,
    timezoneErrorText: PropTypes.string.isRequired,
    loading: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(classes)(Planning);
