import React from "react";
import { Field, initialize, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { teal, grey } from "@material-ui/core/colors";
import { Edit } from "@material-ui/icons";

import translation from "../../translation/translation";
import MuiTextField from "../../components/commons/fields/MuiTextField";
import MuiPhoneField from "../../components/commons/fields/muiPhoneField/MuiPhoneField";
import store from "../../redux/store";

const classes = {
  button: {
    borderRadius: 4,
    fontFamily: "'Montserrat-Regular'",
    lineHeight: "1.2",
    color: teal[500],
    borderColor: teal[500],
    "&:hover": {
      color: teal[500],
      borderColor: teal[500],
      backgroundColor: "rgb(0 150 136 / 16%)",
    },
  },
  editIcon: {
    marginRight: 5,
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    marginTop: 15,
    gap: "10px",
    color: grey[500],
  },
  formControl: {
    width: "100%",
    marginTop: 30,
  },
  accessDetails: {
    width: "100%",
    marginTop: 20,
  },
};

class ManageAccessDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpened: false,
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeValue = this.handleChangeValue.bind(this);
    this.handleChangePhoneInput = this.handleChangePhoneInput.bind(this);
    this.initFormValues = this.initFormValues.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.initFormValues();
  }

  initFormValues() {
    const { order } = this.props;

    if (order) {
      this.setState({
        values: {
          property_contact_name: order.property_contact_name,
          property_contact_phone: order.property_contact_phone,
          property_access_details: order.property_access_details,
          property_contact_comment: order.property_contact_comment,
        },
      });

      store.dispatch(
        initialize("manage_order_access_details", {
          property_contact_name: order.property_contact_name,
          property_contact_phone: order.property_contact_phone,
          property_access_details: order.property_access_details,
          property_contact_comment: order.property_contact_comment,
        })
      );
    }
  }

  handleOpen() {
    this.initFormValues();
    this.setState({ isOpened: true });
  }

  handleClose() {
    this.setState({ isOpened: false, values: {} });
    store.dispatch(
      initialize("manage_order_access_details", {
        property_contact_name: "",
        property_contact_phone: "",
        property_access_details: "",
        property_contact_comment: "",
      })
    );
  }

  handleChangeValue = (name) => (event) => {
    this.setState({
      values: {
        ...this.state.values,
        [name]: event.target.value,
      },
    });
  };

  handleChangePhoneInput = (val) => {
    this.setState({
      values: {
        ...this.state.values,
        property_contact_phone: val,
      },
    });
  };

  handleSubmit() {
    const { values } = this.state;
    const { updateAccessDetails } = this.props;

    updateAccessDetails(values, () => {
      this.handleClose();
    });
  }

  render() {
    const { isOpened, values } = this.state;
    const { classes, isLoading } = this.props;

    return (
      <React.Fragment>
        <Button
          variant="outlined"
          color="primary"
          onClick={this.handleOpen}
          className={classes.button}
          size="small"
        >
          <Edit fontSize="small" className={classes.editIcon} />
          {translation().order_management.access_details.button}
        </Button>
        <Dialog
          fullWidth
          disableBackdropClick={isLoading}
          open={isOpened ? true : false}
          onClose={this.handleClose}
          aria-labelledby="product-property-details"
          aria-describedby="product-property-details-description"
        >
          <DialogTitle id="product-property-details">
            {translation().order_management.access_details.dialog_title}
          </DialogTitle>
          <DialogContent>
            <div className={classes.formControl}>
              <Field
                autoComplete="off"
                disabled={isLoading}
                id="property_contact_name"
                fullWidth
                label={
                  translation().order_management.access_details
                    .property_contact_name
                }
                name="property_contact_name"
                margin="normal"
                onChange={this.handleChangeValue("property_contact_name")}
                type="text"
                value={values.property_contact_name}
                component={MuiTextField}
              />
            </div>
            <div className={classes.formControl}>
              <Field
                id="property_contact_phone"
                name="property_contact_phone"
                disabled={isLoading}
                onChange={this.handleChangePhoneInput}
                placeholder={
                  translation().order_management.access_details
                    .property_contact_phone
                }
                label={
                  translation().order_management.access_details
                    .property_contact_phone
                }
                type="tel"
                value={values.property_contact_phone}
                component={MuiPhoneField}
              />
            </div>
            <div className={classes.accessDetails}>
              <Field
                autoComplete="off"
                id="property_access_details"
                disabled={isLoading}
                fullWidth
                label={
                  translation().order_management.access_details
                    .property_access_details
                }
                margin="normal"
                multiline
                name="property_access_details"
                onChange={this.handleChangeValue("property_access_details")}
                type="text"
                value={values.property_access_details}
                rows="4"
                component={MuiTextField}
              />
            </div>
            <div className={classes.formControl}>
              <Field
                autoComplete="off"
                id="property_contact_comment"
                disabled={isLoading}
                fullWidth
                label={
                  translation().order_management.access_details
                    .property_contact_comment
                }
                margin="normal"
                multiline
                name="property_contact_comment"
                onChange={this.handleChangeValue("property_contact_comment")}
                rows="4"
                type="text"
                value={values.property_contact_comment}
                component={MuiTextField}
              />
            </div>
            {isLoading && (
              <div className={classes.loading}>
                <CircularProgress size={30} color="secondary" />
                <p>
                  {translation().order_management.access_details.edit_loading}
                </p>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              color="default"
              disabled={isLoading}
              size="small"
            >
              {translation().commons.cancel}
            </Button>
            <Button
              color="primary"
              onClick={this.handleSubmit}
              variant="contained"
              disabled={isLoading}
              size="small"
            >
              {translation().commons.confirm}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

ManageAccessDetails.propTypes = {
  classes: PropTypes.shape({
    formControl: PropTypes.string.isRequired,
    loading: PropTypes.string.isRequired,
    editIcon: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
    accessDetails: PropTypes.string.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  order: PropTypes.shape({
    property_contact_name: PropTypes.string,
    property_contact_phone: PropTypes.string,
    property_contact_linking_phone: PropTypes.string,
    property_access_details: PropTypes.string,
    property_contact_comment: PropTypes.string,
  }).isRequired,
  updateAccessDetails: PropTypes.func.isRequired,
  user: PropTypes.shape({
    company: PropTypes.shape({
      tenant: PropTypes.bool,
    }),
  }).isRequired,
};

export default reduxForm({
  form: "manage_order_access_details",
})(withStyles(classes)(ManageAccessDetails));
