import { connect } from "react-redux";

import { fetchApi } from "../../helpers/functions/api";

import {
  clearOrderUpdatePlanning,
  createUpdateCalendar,
  getNextOrderProductSlots,
  getNextOrderProductSlotsError,
  getNextOrderProductSlotsSuccess,
  getPreviousOrderProductSlots,
  getPreviousOrderProductSlotsError,
  getPreviousOrderProductSlotsSuccess,
  getOrderProductSlots,
  getOrderProductSlotsError,
  getOrderProductSlotsSuccess,
  initOrderProductPlanning,
  setUpdateNbTimeMax,
  updateOrderProductStartDate,
  updateOrderProductStartDateError,
  updateOrderProductStartDateSuccess,
} from "../../redux/actions/orderManagement/reschedule";

import { enqueueSnackbar } from "../../redux/actions/notificationsActions";

import translation from "../../translation/translation";

import RescheduleDialog from "../../components/orderManagement/reschedule/RescheduleDialog";
import { addToDate, dateInTz, formatDate } from "../../helpers/functions/dates";

const mapStateToProps = (state) => ({
  slots: state.orderManagement.reschedule.slots,
  nbTimeMax: state.orderManagement.reschedule.nbTimeMax,
  startDate: state.orderManagement.reschedule.startDate,
  endDate: state.orderManagement.reschedule.endDate,
  minStartDate: state.orderManagement.reschedule.minStartDate,
  maxStartDate: state.orderManagement.reschedule.maxStartDate,
  calendar: state.orderManagement.reschedule.calendar,
  isLoading: state.orderManagement.reschedule.isLoading,
  isSendLoading: state.orderManagement.reschedule.isSendLoading,
  isWeekLoading: state.orderManagement.reschedule.isWeekLoading,
  error: state.orderManagement.reschedule.error,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    initOrderProductPlanning(data) {
      dispatch(initOrderProductPlanning(data));
    },
    clearOrderUpdatePlanning() {
      dispatch(clearOrderUpdatePlanning());
    },
    getOrderProductSlots(
      orderProductId,
      options,
      startDate,
      endDate,
      timezone = undefined
    ) {
      if (!orderProductId) return;

      dispatch(getOrderProductSlots());

      const query = {};

      query.start = startDate;
      query.end = endDate;

      if (options && options.length > 0) query.options = options;

      fetchApi(
        "get",
        `provider-companies/self/order-products/${orderProductId}/slots`,
        query,
        null,
        null,
        (slots) => {
          dispatch(getOrderProductSlotsSuccess(slots));

          const calendar = {};
          let nbTimesMax = 0;

          /**
           * Create calendar days
           */
          for (let i = 0; i < 7; i++) {
            let nowTz = dateInTz(startDate, null, timezone);
            let day = addToDate(nowTz, i, "days", "YYYY-MM-DD");
            calendar[day] = [];
          }

          /**
           * Create slots time and push them to their day
           */
          if (slots) {
            for (let j = 0; j < slots.length; j++) {
              let slotDate = dateInTz(slots[j], "YYYY-MM-DD", timezone);
              let slotTime = dateInTz(slots[j], "HH:mm", timezone);

              if (calendar[slotDate]) {
                calendar[slotDate].push(slotTime);
              }
            }
          }

          /**
           * Push new calendar to component state
           */
          dispatch(createUpdateCalendar(calendar));

          /**
           * Calculate the number max of slots in all days
           */
          for (let slotDate in calendar) {
            nbTimesMax = Math.max(5, nbTimesMax, calendar[slotDate].length);
            dispatch(setUpdateNbTimeMax(nbTimesMax));
          }
        },
        (error) => {
          dispatch(
            getOrderProductSlotsError(
              error.response &&
                error.response.data &&
                error.response.data.detail
                ? error.response.data.detail
                : translation().order_management.reschedule.slots.get_error
            )
          );

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().order_management.reschedule.slots.get_error,
                status: error.response.status || undefined,
                options: {
                  variant: "error",
                },
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().order_management.reschedule.slots
                  .get_error,
                options: {
                  variant: "error",
                },
              })
            );
          }
        }
      );
    },
    getNextOrderProductSlots(
      orderProductId,
      options,
      startDate,
      endDate,
      timezone = undefined
    ) {
      if (!orderProductId) return;

      dispatch(getNextOrderProductSlots());

      const query = {};

      if (options && options.length > 0) query.options = options;

      const start = addToDate(startDate, 7, "days");
      const end = addToDate(endDate, 7, "days");
      const startDateFormated = formatDate(start, "YYYY-MM-DD");
      const endDateFormated = formatDate(end, "YYYY-MM-DD");

      query.start = startDateFormated;
      query.end = endDateFormated;

      fetchApi(
        "get",
        `provider-companies/self/order-products/${orderProductId}/slots`,
        query,
        null,
        null,
        (slots) => {
          dispatch(getNextOrderProductSlotsSuccess(slots));

          const calendar = {};
          let nbTimesMax = 0;

          for (let i = 0; i < 7; i++) {
            let nowTz = dateInTz(start, null, timezone);
            let day = addToDate(nowTz, i, "days", "YYYY-MM-DD");
            calendar[day] = [];
          }

          if (slots) {
            for (let j = 0; j < slots.length; j++) {
              let slotDate = dateInTz(slots[j], "YYYY-MM-DD", timezone);
              let slotTime = dateInTz(slots[j], "HH:mm", timezone);

              if (calendar[slotDate]) {
                calendar[slotDate].push(slotTime);
              }
            }
          }

          dispatch(createUpdateCalendar(calendar, start, end));

          /**
           * Calculate the number max of slots in all days
           */
          for (let slotDate in calendar) {
            nbTimesMax = Math.max(5, nbTimesMax, calendar[slotDate].length);
            dispatch(setUpdateNbTimeMax(nbTimesMax));
          }
        },
        (error) => {
          dispatch(
            getNextOrderProductSlotsError(
              error.response &&
                error.response.data &&
                error.response.data.detail
                ? error.response.data.detail
                : translation().order_management.reschedule.slots.get_error
            )
          );

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().order_management.reschedule.slots.get_error,
                status: error.response.status || undefined,
                options: {
                  variant: "error",
                },
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().order_management.reschedule.slots
                  .get_error,
                options: {
                  variant: "error",
                },
              })
            );
          }
        }
      );
    },
    getPreviousOrderProductSlots(
      orderId,
      orderProductId,
      options,
      startDate,
      endDate,
      timezone = undefined
    ) {
      if (!orderId || !orderProductId) return;

      dispatch(getPreviousOrderProductSlots());

      const query = {};

      if (options && options.length > 0) query.options = options;

      const start = addToDate(startDate, -7, "days");
      const end = addToDate(endDate, -7, "days");
      const startDateFormated = formatDate(start, "YYYY-MM-DD");
      const endDateFormated = formatDate(end, "YYYY-MM-DD");

      query.start = startDateFormated;
      query.end = endDateFormated;

      fetchApi(
        "get",
        `provider-companies/self/order-products/${orderProductId}/slots`,
        query,
        null,
        null,
        (slots) => {
          dispatch(getPreviousOrderProductSlotsSuccess(slots));

          const calendar = {};
          let nbTimesMax = 0;

          for (let i = 0; i < 7; i++) {
            let nowTz = dateInTz(start, null, timezone);
            let day = addToDate(nowTz, i, "days", "YYYY-MM-DD");
            calendar[day] = [];
          }

          if (slots) {
            for (let j = 0; j < slots.length; j++) {
              let slotDate = dateInTz(slots[j], "YYYY-MM-DD", timezone);
              let slotTime = dateInTz(slots[j], "HH:mm", timezone);

              if (calendar[slotDate]) {
                calendar[slotDate].push(slotTime);
              }
            }
          }

          dispatch(createUpdateCalendar(calendar, start, end));

          for (let slotDate in calendar) {
            nbTimesMax = Math.max(5, nbTimesMax, calendar[slotDate].length);
            dispatch(setUpdateNbTimeMax(nbTimesMax));
          }
        },
        (error) => {
          dispatch(
            getPreviousOrderProductSlotsError(
              error.response &&
                error.response.data &&
                error.response.data.detail
                ? error.response.data.detail
                : translation().order_management.reschedule.slots.get_error
            )
          );

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().order_management.reschedule.slots.get_error,
                status: error.response.status || undefined,
                options: {
                  variant: "error",
                },
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().order_management.reschedule.slots
                  .get_error,
                options: {
                  variant: "error",
                },
              })
            );
          }
        }
      );
    },
    updateOrderProductPlanning(orderProductId, start, onSuccess) {
      if (!orderProductId || !start) return;

      dispatch(updateOrderProductStartDate());

      fetchApi(
        "put",
        `provider-companies/self/order-products/${orderProductId}/start`,
        null,
        { start },
        null,
        () => {
          dispatch(updateOrderProductStartDateSuccess());
          dispatch(
            enqueueSnackbar({
              message: translation().order_management.reschedule.update.success,
              options: {
                variant: "success",
              },
            })
          );

          if (onSuccess && typeof onSuccess === "function") {
            onSuccess();
          }

          if (ownProps && ownProps.onActionSuccess) {
            ownProps.onActionSuccess();
          }
        },
        (error) => {
          dispatch(updateOrderProductStartDateError(true, error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().order_management.reschedule.update.error,
                status: error.response.status || undefined,
                options: {
                  variant: "error",
                },
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().order_management.reschedule.update.error,
                options: {
                  variant: "error",
                },
              })
            );
          }
        }
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RescheduleDialog);
