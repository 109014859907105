import React from "react";
import { change, Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { orange, grey } from "@material-ui/core/colors";
import { Edit } from "@material-ui/icons";

import translation from "../../translation/translation";
import store from "../../redux/store";
import MuiSelectField from "../commons/fields/MuiSelectField";

const classes = {
  button: {
    borderRadius: 4,
    fontFamily: "'Montserrat-Regular'",
    lineHeight: "1.2",
    color: orange[300],
    borderColor: orange[300],
    "&:hover": {
      color: orange[300],
      borderColor: orange[300],
      backgroundColor: "rgb(255 152 0 / 16%)",
    },
  },
  editIcon: {
    marginRight: 5,
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    marginTop: 15,
    gap: "10px",
    color: grey[500],
  },
};

class ManageProviderDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpened: false,
      value: "",
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleDialogEnter = this.handleDialogEnter.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleOpen() {
    this.setState({ isOpened: true });
  }

  handleClose() {
    this.setState({ isOpened: false, value: "" });
    store.dispatch(change("manage_order_provider", "order_provider", ""));
  }

  handleDialogEnter() {
    const { getOrderProviders } = this.props;

    getOrderProviders();
  }

  handleSelect(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit() {
    const { value } = this.state;
    const { updateProvider } = this.props;

    updateProvider({ provider_id: value }, () => {
      this.handleClose();
    });
  }

  render() {
    const { isOpened, value } = this.state;
    const { classes, data, getLoading, updateLoading } = this.props;

    return (
      <React.Fragment>
        <Button
          variant="outlined"
          color="primary"
          onClick={this.handleOpen}
          className={classes.button}
          size="small"
        >
          <Edit fontSize="small" className={classes.editIcon} />
          {translation().order_management.provider.button}
        </Button>
        <Dialog
          fullWidth
          open={isOpened ? true : false}
          onClose={this.handleClose}
          aria-labelledby="edit-provider"
          aria-describedby="edit-provider-description"
          disableBackdropClick={getLoading || updateLoading}
          onEnter={this.handleDialogEnter}
        >
          <DialogTitle id="edit-provider">
            {translation().order_management.provider.dialog_title}
          </DialogTitle>
          <DialogContent>
            {data && data.length > 0 ? (
              <FormControl fullWidth>
                {!value && (
                  <InputLabel for="order_provider">
                    {translation().order_management.provider.label}
                  </InputLabel>
                )}
                <Field
                  component={MuiSelectField}
                  fullWidth
                  id="order_provider"
                  name="order_provider"
                  displayEmpty
                  disabled={updateLoading || getLoading}
                  onChange={this.handleSelect}
                  value={value}
                  required
                >
                  <option />
                  {data.map((provider, index) => (
                    <option key={index} value={provider.id}>
                      {provider.name}
                    </option>
                  ))}
                </Field>
              </FormControl>
            ) : (
              !getLoading && (
                <Typography>
                  {translation().order_management.provider.empty}
                </Typography>
              )
            )}
            {getLoading && (
              <div className={classes.loading}>
                <CircularProgress size={30} color="secondary" />
                <p>{translation().order_management.provider.loading_text}</p>
              </div>
            )}
            {updateLoading && (
              <div className={classes.loading}>
                <CircularProgress size={30} color="secondary" />
                <p>{translation().order_management.provider.edit_loading}</p>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              color="default"
              disabled={getLoading || updateLoading}
              size="small"
            >
              {translation().commons.cancel}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleSubmit}
              disabled={updateLoading || !value}
              size="small"
            >
              {translation().commons.confirm}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

ManageProviderDialog.propTypes = {
  classes: PropTypes.shape({
    loading: PropTypes.string.isRequired,
    editIcon: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
  }).isRequired,
  getLoading: PropTypes.bool,
  updateLoading: PropTypes.bool,
  getOrderProviders: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()),
  updateProvider: PropTypes.func.isRequired,
};

export default reduxForm({
  form: "manage_order_provider",
})(withStyles(classes)(ManageProviderDialog));
