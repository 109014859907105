import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Chip from "@material-ui/core/Chip";

import EditAvailabilityForm from "../forms/availabilities/edit/EditAvailabilityForm";
import EditUnavailabilityForm from "../forms/unavailabilities/edit/EditUnavailabilityForm";
import {
  convertMinsToHrsMins,
  formatDate,
} from "../../../helpers/functions/dates";
import { pad } from "../../../helpers/functions/utils";
import { getUserTimezone } from "../../../helpers/functions/user";
import { getLocale } from "../../../helpers/functions/cookies";
import translation from "../../../translation/translation";
import OrderManagment from "../../../components/orderManagement/OrderManagment";

class ScheduleEventPopup extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isEditAvailabilityOpen: false,
      isEditUnavailabilityOpen: false,
    };

    this.handleToggleEditAvailability = this.handleToggleEditAvailability.bind(
      this
    );
    this.handleToggleEditUnavailability = this.handleToggleEditUnavailability.bind(
      this
    );

    this.handleDeleteAvailability = this.handleDeleteAvailability.bind(this);
    this.handleEditAvailability = this.handleEditAvailability.bind(this);

    this.handleDeleteUnavailability = this.handleDeleteUnavailability.bind(
      this
    );
    this.handleEditUnavailability = this.handleEditUnavailability.bind(this);

    this.renderAvailability = this.renderAvailability.bind(this);
    this.renderUnavailability = this.renderUnavailability.bind(this);
    this.renderOrderProduct = this.renderOrderProduct.bind(this);
  }

  handleToggleEditAvailability() {
    this.setState((state) => ({
      isEditAvailabilityOpen: !state.isEditAvailabilityOpen,
    }));
  }

  handleToggleEditUnavailability() {
    this.setState((state) => ({
      isEditUnavailabilityOpen: !state.isEditUnavailabilityOpen,
    }));
  }

  handleEditAvailability(values) {
    if (!values) return;

    const {
      editAvailability,
      event: { id, start },
    } = this.props;

    if (id && start) {
      editAvailability(id, values, start);
    }
  }

  handleDeleteAvailability() {
    const {
      removeAvailability,
      event: { id },
    } = this.props;

    if (id) {
      removeAvailability(id);
    }
  }

  handleEditUnavailability(values) {
    const { editUnavailability, event } = this.props;

    if (!values || !event) return;

    editUnavailability(event, values);
  }

  handleDeleteUnavailability() {
    const {
      removeUnavailability,
      event: { id },
    } = this.props;

    if (id) {
      removeUnavailability(id);
    }
  }

  renderOrderProduct(event) {
    const startHour = pad(event.start.getHours());
    const startMinutes = pad(event.start.getMinutes());
    const endHour = pad(event.end.getHours());
    const endMinutes = pad(event.end.getMinutes());

    const { user, refreshSchedule } = this.props;

    return (
      <div className={event.className}>
        <div className="event-pp-header">
          <div className="event-actions">
            <IconButton
              aria-label="Close"
              className="event-action"
              onClick={this.props.onClose}
            >
              <span className="icon-fermer" />
            </IconButton>
          </div>
          <div className="event-title">
            {event.product && event.product.icon_url_light && (
              <img
                className="product-img"
                src={event.product.icon_url_light}
                alt="icon status"
              />
            )}
            {event.title}
          </div>
        </div>
        <div className="event-pp-content">
          {user &&
            user.data &&
            user.data.company &&
            user.data.company.tenant &&
            user.data.company_admin &&
            event.product.status &&
            event.product.status.shortcode &&
            event.product.status.shortcode === "planned" && (
              <OrderManagment
                order={{
                  id: event.id,
                  start: event.start ? event.start : null,
                  property_contact_name: event.product.property_contact_name
                    ? event.product.property_contact_name
                    : "",
                  property_contact_phone: event.product.property_contact_phone
                    ? event.product.property_contact_phone
                    : "",
                  property_contact_linking_phone: event.product
                    .property_contact_linking_phone
                    ? event.product.property_contact_linking_phone
                    : "",
                  property_access_details: event.product.property_access_details
                    ? event.product.property_access_details
                    : "",
                  property_contact_comment: event.product
                    .property_contact_comment
                    ? event.product.property_contact_comment
                    : "",
                  options: event.product.options ? event.product.options : null,
                  order: {
                    property: {
                      timezone_code:
                        event.product.property &&
                        event.product.property.timezone_code
                          ? event.product.property.timezone_code
                          : "",
                    },
                  },
                }}
                onActionSuccess={refreshSchedule}
              />
            )}
          <p className="detail-item">{translation().schedule.orders.infos}</p>
          {event.start && event.end && (
            <span className="detail">
              <span className="detail-icon icon-horaire detail-date" />
              <div className="detail-date">
                {event.start.toLocaleDateString(getLocale(), {
                  weekday: "long",
                  month: "long",
                  day: "numeric",
                })}{" "}
                <br />
                <span className="app-dark-grey" style={{ fontWeight: 500 }}>
                  {startHour}:{startMinutes} - {endHour}:{endMinutes}
                </span>
              </div>
            </span>
          )}
          {event.timezone_code &&
            getUserTimezone() !== event.timezone_code &&
            event.local_start &&
            event.local_end && (
              <span className="detail timezone-warning">
                <span className="detail-icon icon-infos detail-date" />
                <div className="detail-date">
                  {translation().commons.local_datetime}:{" "}
                  {formatDate(event.local_start, "localized-datetime")} <br />
                  <span>({event.timezone_code})</span>
                </div>
              </span>
            )}
          {event.product &&
            event.product &&
            event.product.property &&
            event.product.property.address && (
              <span className="detail">
                <span className="detail-icon icon-comment01" />
                {event.product.property.address}
              </span>
            )}
          <div>
            {event.product &&
            event.product &&
            event.product.property_size_formatted ? (
              <span className="detail">
                <span className="detail-icon icon-surface" />
                {event.product.property_size_formatted}
              </span>
            ) : (
              ""
            )}
            {event.product && event.product.duration ? (
              <span className="detail">
                <span className="detail-icon icon-duree" />
                {event.product &&
                  event.product.duration &&
                  convertMinsToHrsMins(event.product.duration, "h")}
              </span>
            ) : (
              ""
            )}
          </div>
          {event.order_id && (
            <span className="detail">
              <span className="detail-icon icon-panier" />
              {translation().schedule.orders.order} n° {event.order_id}
              {event.id &&
                " | " +
                  translation().schedule.orders.product +
                  " n° " +
                  event.id}
            </span>
          )}
          <div>
            {event.product && event.product.property_contact_name ? (
              <span className="detail">
                <span className="detail-icon icon-connecte" />
                <span className="detail-name">
                  {translation().schedule.orders.on_place}:{" "}
                </span>
                <span className="app-dark-grey">
                  {event.product.property_contact_name}{" "}
                  {user &&
                  user.data &&
                  user.data.company &&
                  user.data.company.tenant &&
                  user.data.company_admin &&
                  event.product.property_contact_phone
                    ? "(" + event.product.property_contact_phone + ")"
                    : event.product.property_contact_linking_phone
                    ? "(" + event.product.property_contact_linking_phone + ")"
                    : ""}
                </span>
              </span>
            ) : (
              ""
            )}
          </div>
          <div>
            {event.product && event.product.property_access_details ? (
              <span className="detail" style={{ marginTop: 15 }}>
                <span className="detail-name">
                  {translation().schedule.orders.details}:
                </span>{" "}
                <br />
                <span className="app-dark-grey">
                  {event.product.property_access_details}
                </span>
              </span>
            ) : (
              ""
            )}
          </div>
          <div>
            {event.product && event.product.property_contact_comment ? (
              <span className="detail" style={{ marginTop: 15 }}>
                <span className="detail-name">
                  {translation().schedule.orders.comment}:
                </span>{" "}
                <br />
                <span className="app-dark-grey">
                  {event.product.property_contact_comment}
                </span>
              </span>
            ) : (
              ""
            )}
          </div>
          {event.product &&
          event.product.options &&
          event.product.options.length ? (
            <div className="options">
              <p className="detail-item">
                {translation().schedule.orders.options}:
              </p>
              <div style={{ maxHeight: 92, overflow: "auto" }}>
                {event.product.options.map((option, index) => {
                  return <Chip key={index} label={option} className="option" />;
                })}
              </div>
            </div>
          ) : (
            ""
          )}
          {event.product &&
            event.product.status &&
            event.product.status.icon_url_dark && (
              <div className="detail status">
                <p className="detail-item" style={{ marginTop: 20 }}>
                  {translation().schedule.orders.status}:
                </p>
                <img
                  src={event.product.status.icon_url_dark}
                  alt={
                    event.product.status.name ? event.product.status.name : ""
                  }
                  className="status-img"
                />
                <span
                  style={{
                    fontWeight: 500,
                    color: event.product.status.color
                      ? event.product.status.color
                      : "#000000",
                  }}
                >
                  {event.product.status.name && event.product.status.name}
                </span>
              </div>
            )}
        </div>
      </div>
    );
  }

  renderAvailability(event) {
    const { isEditAvailabilityOpen } = this.state;

    const {
      maxHour,
      minHour,
      onClose,
      deleteAvailabilityLoading,
      editAvailabilityLoading,
      readOnly,
    } = this.props;

    const startHour = pad(event.start.getHours());
    const startMinutes = pad(event.start.getMinutes());
    const endHour = pad(event.end.getHours());
    const endMinutes = pad(event.end.getMinutes());

    return (
      <div className={event.className}>
        <div className="event-pp-header">
          <div className="event-actions">
            {!readOnly && (
              <IconButton
                aria-label="Delete availability"
                className="event-action"
                disabled={deleteAvailabilityLoading}
                onClick={this.handleDeleteAvailability}
              >
                <span className="icon-delete" />
              </IconButton>
            )}
            <IconButton
              aria-label="Close availability"
              className="event-action"
              disabled={deleteAvailabilityLoading}
              onClick={onClose}
            >
              <span className="icon-fermer" />
            </IconButton>
          </div>
          <div className="event-title">{event.title}</div>
          {!readOnly && (
            <div
              className="edit-event"
              onClick={this.handleToggleEditAvailability}
            >
              <span className="icon-editer" />
            </div>
          )}
        </div>
        <div className="event-pp-content">
          {event.start && event.end && (
            <div>
              {isEditAvailabilityOpen && !readOnly ? (
                <div className="edit-availability">
                  <span className="detail">
                    <span className="icon-horaire date-icon" />
                    <div className="detail-date">
                      {event.start.toLocaleDateString(getLocale(), {
                        weekday: "long",
                        month: "long",
                        day: "numeric",
                      })}{" "}
                      <br />
                    </div>
                  </span>
                  <EditAvailabilityForm
                    onSubmit={this.handleEditAvailability}
                    maxHour={maxHour}
                    minHour={minHour}
                    initialValues={{
                      hour_start: startHour.toString(),
                      min_start: startMinutes.toString(),
                      hour_end: endHour.toString(),
                      min_end: endMinutes.toString(),
                    }}
                    editAction={{
                      loading: editAvailabilityLoading,
                    }}
                  />
                </div>
              ) : (
                <span className="detail">
                  <span className="detail-icon icon-horaire detail-date" />
                  <div className="detail-date">
                    {event.start.toLocaleDateString(getLocale(), {
                      weekday: "long",
                      month: "long",
                      day: "numeric",
                    })}{" "}
                    <br />
                    <div>
                      <span className="app-dark-grey">
                        {startHour}:{startMinutes} - {endHour}:{endMinutes}
                      </span>
                    </div>
                  </div>
                </span>
              )}
            </div>
          )}
          {deleteAvailabilityLoading && (
            <div className="center-loader">
              <CircularProgress size={35} color="secondary" />
            </div>
          )}
        </div>
      </div>
    );
  }

  renderUnavailability(event) {
    const { isEditUnavailabilityOpen } = this.state;

    const {
      maxHour,
      minHour,
      onClose,
      deleteUnavailabilityLoading,
      editUnavailabilityLoading,
      readOnly,
    } = this.props;

    const startHour = pad(event.start.getHours());
    const startMinutes = pad(event.start.getMinutes());
    const endHour = pad(event.end.getHours());
    const endMinutes = pad(event.end.getMinutes());

    return (
      <div className={event.className}>
        <div className="event-pp-header">
          <div className="event-actions">
            {!readOnly && (
              <IconButton
                aria-label="Delete unavailability"
                className="event-action"
                disabled={deleteUnavailabilityLoading}
                onClick={this.handleDeleteUnavailability}
              >
                <span className="icon-delete" />
              </IconButton>
            )}
            <IconButton
              aria-label="Close unavailability"
              className="event-action"
              disabled={deleteUnavailabilityLoading}
              onClick={onClose}
            >
              <span className="icon-fermer" />
            </IconButton>
          </div>
          <div className="event-title">
            {event.title ||
              translation().schedule.unavailability.unavailability}
          </div>
          {!readOnly && (
            <div
              className="edit-event"
              onClick={this.handleToggleEditUnavailability}
            >
              <span className="icon-editer" />
            </div>
          )}
        </div>
        <div
          className="event-pp-content"
          style={isEditUnavailabilityOpen ? { paddingTop: 20 } : {}}
        >
          {event.start && event.end && (
            <div>
              {isEditUnavailabilityOpen && !readOnly ? (
                <div className="new-unavailability">
                  <EditUnavailabilityForm
                    onSubmit={this.handleEditUnavailability}
                    editAction={{
                      loading: editUnavailabilityLoading,
                    }}
                    initialValues={{
                      location:
                        event.location && event.location.address
                          ? event.location.address
                          : "",
                      longitude:
                        event.location && event.location.longitude
                          ? event.location.longitude
                          : "",
                      latitude:
                        event.location && event.location.latitude
                          ? event.location.latitude
                          : "",
                      hour_start: startHour.toString(),
                      min_start: startMinutes.toString(),
                      hour_end: endHour.toString(),
                      min_end: endMinutes.toString(),
                    }}
                    maxHour={maxHour}
                    minHour={minHour}
                  />
                </div>
              ) : (
                <div>
                  <span className="detail">
                    <span className="detail-icon icon-horaire detail-date" />
                    <div className="detail-date">
                      {event.start.toLocaleDateString(getLocale(), {
                        weekday: "long",
                        month: "long",
                        day: "numeric",
                      })}{" "}
                      <br />
                      <div>
                        <span className="app-dark-grey">
                          {startHour}:{startMinutes} - {endHour}:{endMinutes}
                        </span>
                      </div>
                    </div>
                  </span>
                  {event.location && (
                    <span className="detail">
                      <span className="detail-icon detail-date icon-comment01" />
                      <div className="detail-date">
                        {event.location.address}
                        {event.location.latitude && event.location.longitude && (
                          <div>
                            <span className="app-dark-grey">
                              {event.location.latitude} /{" "}
                              {event.location.longitude}
                            </span>
                          </div>
                        )}
                      </div>
                    </span>
                  )}
                </div>
              )}
            </div>
          )}
          {deleteUnavailabilityLoading && (
            <div className="center-loader">
              <CircularProgress size={35} color="secondary" />
            </div>
          )}
        </div>
      </div>
    );
  }

  render() {
    const { event } = this.props;

    return (
      <div className="event-popup">
        {event.type && event.type === "availability"
          ? this.renderAvailability(event)
          : ""}
        {event.type && event.type === "unavailability"
          ? this.renderUnavailability(event)
          : ""}
        {event.type && event.type === "orderProduct"
          ? this.renderOrderProduct(event)
          : ""}
      </div>
    );
  }
}

ScheduleEventPopup.propTypes = {
  editAvailability: PropTypes.func.isRequired,
  editAvailabilityLoading: PropTypes.bool,
  editUnavailability: PropTypes.func.isRequired,
  editUnavailabilityLoading: PropTypes.bool,
  event: PropTypes.shape({
    id: PropTypes.any,
    start: PropTypes.any.isRequired,
    end: PropTypes.any.isRequired,
    type: PropTypes.string,
    timezone_code: PropTypes.string,
  }).isRequired,
  deleteAvailabilityLoading: PropTypes.bool,
  deleteUnavailabilityLoading: PropTypes.bool,
  maxHour: PropTypes.number.isRequired,
  minHour: PropTypes.number.isRequired,
  removeAvailability: PropTypes.func.isRequired,
  removeUnavailability: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  user: PropTypes.shape({
    data: PropTypes.shape({
      company: PropTypes.shape({
        tenant: PropTypes.bool,
      }),
      company_admin: PropTypes.bool,
    }),
  }).isRequired,
  refreshSchedule: PropTypes.func.isRequired,
};

export default ScheduleEventPopup;
