export const updateAccessDetails = () => ({
  type: "UPDATE_ORDER_ACCESS_DETAILS",
});

export const updateAccessDetailsSuccess = (data) => ({
  type: "UPDATE_ORDER_ACCESS_DETAILS_SUCCESS",
  data,
});

export const updateAccessDetailsFailure = (error) => ({
  type: "UPDATE_ORDER_ACCESS_DETAILS_FAILURE",
  error,
});
