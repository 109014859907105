import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { fetchApi } from "../../helpers/functions/api";
import { enqueueSnackbar } from "../../redux/actions/notificationsActions";
import {
  getOrder,
  getOrderSuccess,
  getOrderFailure,
  getProductMediaGroups,
  getProductMediaGroupsSuccess,
  getProductMediaGroupsFailure,
  postOrderProductIdMedias,
  postOrderProductIdMediasSuccess,
  postOrderProductIdMediasFailure,
  rateClient,
  rateClientSuccess,
  rateClientError,
} from "../../redux/actions/orderActions";
import { clearMediaGroups } from "../../redux/actions/ordersActions";
import translation from "../../translation/translation";
import Order from "../../views/order/Order";

const mapStateToProps = (state) => ({
  order: state.order,
  user: state.user,
  orderProductMedias: state.orderProductMedias,
  provider: state.provider,
});

const mapDispatchToProps = (dispatch) => {
  const checkBeforeSubmit = (mediasOrderArray) => {
    /**
     * Check if all media templates are set
     */
    for (let i = 0; i < mediasOrderArray.length; i++) {
      if (
        mediasOrderArray[i]["media_template_id"] !== undefined &&
        mediasOrderArray[i].media_template_id === "null"
      )
        return false;
    }
    return true;
  };

  const getProviderOrder = (id, mode = "provider") => {
    if (!id) return;

    dispatch(getOrder());

    fetchApi(
      "get",
      mode === "company"
        ? "provider-companies/self/order-products/" + id
        : "providers/self/order-products/" + id,
      null,
      null,
      null,
      (data) => {
        dispatch(getOrderSuccess(data));
      },
      (error) => {
        const errorMessage = translation().orders.callbacks.get_order_failed;

        if (error.response)
          dispatch(
            enqueueSnackbar({
              message:
                error.response.data && error.response.data.detail
                  ? error.response.data.detail
                  : errorMessage,
              options: {
                variant: "error",
              },
            })
          );
        else
          dispatch(
            enqueueSnackbar({
              message: errorMessage,
              options: {
                variant: "error",
              },
            })
          );

        dispatch(getOrderFailure(error));
      }
    );
  };

  return {
    getOrder: (id, mode) => {
      getProviderOrder(id, mode);
    },
    clearMediaGroups: () => {
      dispatch(clearMediaGroups());
    },
    getProductMediaGroups: (id, mode) => {
      if (!id) return;

      dispatch(getProductMediaGroups());

      fetchApi(
        "get",
        mode === "company"
          ? "provider-companies/self/order-products/" + id + "/medias"
          : "providers/self/order-products/" + id + "/medias",
        null,
        null,
        null,
        (data) => {
          dispatch(getProductMediaGroupsSuccess(data));
        },
        (error) => {
          dispatch(getProductMediaGroupsFailure(error));
        }
      );
    },
    uploadOrder(
      ordersProductMedias,
      productId,
      callbackSuccess,
      callbackFailure
    ) {
      if (!ordersProductMedias || !productId) return;

      let mediasOrderArray = [];

      /**
       * Loop on all orderProductsMedias mediasTypes
       * Then get the medias for all mediasTypes and push it to the array for send to api
       */
      if (Object.keys(ordersProductMedias).length) {
        for (let mediaType in ordersProductMedias) {
          if (
            ordersProductMedias[mediaType].medias &&
            ordersProductMedias[mediaType].medias.length
          ) {
            for (
              let j = 0;
              j < ordersProductMedias[mediaType].medias.length;
              j++
            ) {
              let mediasToPost = {
                url: ordersProductMedias[mediaType].medias[j].url
                  ? ordersProductMedias[mediaType].medias[j].url
                  : null,
                media_type_id: ordersProductMedias[mediaType].id
                  ? ordersProductMedias[mediaType].id
                  : null,
                floor: ordersProductMedias[mediaType].medias[j].floor
                  ? ordersProductMedias[mediaType].medias[j].floor
                  : null,
                position: ordersProductMedias[mediaType].medias[j].position
                  ? ordersProductMedias[mediaType].medias[j].position
                  : null,
              };

              /**
               * Check if media have media template id key and if his value exists, then pass value to it
               */
              let media_template_id = null;

              if (
                ordersProductMedias[mediaType].medias[j].media_type &&
                ordersProductMedias[mediaType].medias[j].media_type
                  .has_templates
              ) {
                if (
                  ordersProductMedias[mediaType].medias[j]["media_template_id"]
                ) {
                  media_template_id =
                    ordersProductMedias[mediaType].medias[j].media_template_id;
                } else if (
                  ordersProductMedias[mediaType].medias[j]["media_template"] &&
                  ordersProductMedias[mediaType].medias[j].media_template["id"]
                ) {
                  media_template_id =
                    ordersProductMedias[mediaType].medias[j].media_template.id;
                } else if (
                  ordersProductMedias[mediaType].medias[j]["media_template"] ===
                  null
                ) {
                  media_template_id = "null";
                }

                if (media_template_id)
                  mediasToPost.media_template_id = media_template_id;
              }

              mediasOrderArray.push(mediasToPost);
            }
          }
        }
      }

      /**
       * Check if all media templates fields are filled
       */
      if (!checkBeforeSubmit(mediasOrderArray)) {
        const message = translation().orders.callbacks.fill_templates;

        dispatch(
          enqueueSnackbar({
            message,
            options: {
              variant: "warning",
            },
          })
        );

        return;
      }

      const mediasOrder = {
        medias: mediasOrderArray,
      };

      dispatch(postOrderProductIdMedias());

      if (productId) {
        fetchApi(
          "post",
          "providers/self/order-products/" + productId + "/medias",
          null,
          mediasOrder,
          null,
          () => {
            const message = translation().orders.callbacks.medias_send_success;

            dispatch(
              enqueueSnackbar({
                message,
                options: {
                  variant: "success",
                },
              })
            );

            dispatch(postOrderProductIdMediasSuccess());

            if (callbackSuccess && typeof callbackSuccess === "function")
              callbackSuccess();
          },
          (error) => {
            const errorMessage = translation().orders.callbacks
              .medias_send_failure;

            if (error.response) {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : errorMessage,
                  options: {
                    variant: "error",
                  },
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message: errorMessage,
                  options: {
                    variant: "error",
                  },
                })
              );
            }

            if (callbackFailure && typeof callbackFailure === "function")
              callbackFailure();

            dispatch(postOrderProductIdMediasFailure(error));
          }
        );
      }
    },
    rateClient(values, productId, callbackSuccess) {
      if (!values || !values.rating || !productId) return;

      dispatch(rateClient());

      fetchApi(
        "post",
        `providers/self/order-products/${productId}/rate`,
        null,
        values,
        null,
        () => {
          const message = translation().orders.rating.form.callbacks.success;

          dispatch(
            enqueueSnackbar({
              message,
              options: {
                variant: "success",
              },
            })
          );

          dispatch(rateClientSuccess());

          if (callbackSuccess && typeof callbackSuccess === "function")
            callbackSuccess();
        },
        (error) => {
          const errorMessage = translation().orders.rating.form.callbacks.error;

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : errorMessage,
                options: {
                  variant: "error",
                },
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: errorMessage,
                options: {
                  variant: "error",
                },
              })
            );
          }

          dispatch(rateClientError(error));
        }
      );
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Order));
